/* Print Fonts */
@font-face {
    font-family: 'caviar_dreamsbold';
    src: url('../fonts/PrintFonts/A4-CaviarDreams/caviardreams_bold.woff2') format('woff2'),
         url('../fonts/PrintFonts/A4-CaviarDreams/caviardreams_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'janefrediction';
    src: url('../fonts/PrintFonts/A5-JaneFrediction/JaneFrediction.woff2') format('woff2'),
         url('../fonts/PrintFonts/A5-JaneFrediction/JaneFrediction.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'pumpkinpielatte';
    src: url('../fonts/PrintFonts/A3-PumpkinPieLatte/Pumpkin-Pie-Lattes-FREE.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gloucester';
    src: url('../fonts/PrintFonts/A6-GloucesterMTExtraCondensed/glecb.woff2') format('woff2'),
         url('../fonts/PrintFonts/A6-GloucesterMTExtraCondensed/glecb.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'steelfish';
    src: url('../fonts/PrintFonts/A7-Steelfish/steelfish_bd.woff2') format('woff2'),
         url('../fonts/PrintFonts/A7-Steelfish/steelfish_bd.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'laborunion';
    src: url('../fonts/PrintFonts/A8-LaborUnion/LaborUnion-Regular.woff2') format('woff2'),
        url('../fonts/PrintFonts/A8-LaborUnion/LaborUnion-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'davishboldinline';
    src: url('../fonts/PrintFonts/A9-DavishBoldInline/DavishBoldInline.woff2') format('woff2'),
         url('../fonts/PrintFonts/A9-DavishBoldInline/DavishBoldInline.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* SCript Fonts */
@font-face {
    font-family: 'babyhomeregular';
    src: url('../fonts/ScriptFonts/A-Babyhome/babyhome.woff2') format('woff2'),
         url('../fonts/ScriptFonts/A-Babyhome/babyhome.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'hertine';
    src: url('../fonts/ScriptFonts/A1-Hertine/hertine.woff2') format('woff2'),
         url('../fonts/ScriptFonts/A1-Hertine/hertine.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'josephsophiaregular';
    src: url('../fonts/ScriptFonts/A2-Josephsophia/josephsophia.woff2') format('woff2'),
         url('../fonts/ScriptFonts/A2-Josephsophia/josephsophia.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alexbrushregular';
    src: url('../fonts/ScriptFonts/B-AlexBrush/alexbrush-regular.woff2') format('woff2'),
         url('../fonts/ScriptFonts/B-AlexBrush/alexbrush-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'soulmateregular';
    src: url('../fonts/ScriptFonts/C-Soulmate/soulmate.woff2') format('woff2'),
         url('../fonts/ScriptFonts/C-Soulmate/soulmate.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'wedding_by_mandalaregular';
    src: url('../fonts/ScriptFonts/D-WeddingByMandala/wedding_by_mandala.woff2') format('woff2'),
         url('../fonts/ScriptFonts/D-WeddingByMandala/wedding_by_mandala.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'amarilloregular';
    src: url('../fonts/ScriptFonts/E-Amarillo/amarillo.woff2') format('woff2'),
         url('../fonts/ScriptFonts/E-Amarillo/amarillo.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'vistadaliva';
    src: url('../fonts/ScriptFonts/F-VistaLaDiva/VistaLaDiva.woff2') format('woff2'),
         url('../fonts/ScriptFonts/F-VistaLaDiva/VistaLaDiva.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'amalfi_coastregular';
    src: url('../fonts/ScriptFonts/H-AmalfiCoast/amalfi_coast.woff2') format('woff2'),
         url('../fonts/ScriptFonts/H-AmalfiCoast/amalfi_coast.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'madina_cleanregular';
    src: url('../fonts/ScriptFonts/I-MadinaClean/set_sail_studios_-_madina_clean.woff2') format('woff2'),
         url('../fonts/ScriptFonts/I-MadinaClean/set_sail_studios_-_madina_clean.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'summerstylish';
    src: url('../fonts/ScriptFonts/J-SummerStylish/SummerStylish.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hello_valenticaregular';
    src: url('../fonts/ScriptFonts/K-HelloValentica/hello_valentina.woff2') format('woff2'),
         url('../fonts/ScriptFonts/K-HelloValentica/hello_valentina.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'friday_vibesregular';
    src: url('../fonts/ScriptFonts/L-FridayVibes/friday_vibes.woff2') format('woff2'),
         url('../fonts/ScriptFonts/L-FridayVibes/friday_vibes.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'milkshakeregular';
    src: url('../fonts/ScriptFonts/M-Milkshake/milkshake.woff2') format('woff2'),
         url('../fonts/ScriptFonts/M-Milkshake/milkshake.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'exposedly_personal_useregular';
    src: url('../fonts/ScriptFonts/N-Exposedly/exposedly.woff2') format('woff2'),
         url('../fonts/ScriptFonts/N-Exposedly/exposedly.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'showloveregular';
    src: url('../fonts/ScriptFonts/O-Showlove/showlove-regular.woff2') format('woff2'),
         url('../fonts/ScriptFonts/O-Showlove/showlove-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'royal_stamford_demoregular';
    src: url('../fonts/ScriptFonts/P-RoyalStamford/royal_stamford.woff2') format('woff2'),
         url('../fonts/ScriptFonts/P-RoyalStamford/royal_stamford.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ortisan_signatureregular';
    src: url('../fonts/ScriptFonts/Q-OrtisanSignature//ortisansignature.woff2') format('woff2'),
         url('../fonts/ScriptFonts/Q-OrtisanSignature//ortisansignature.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'jenthillregular';
    src: url('../fonts/ScriptFonts/R-Jenthill/jenthill.woff2') format('woff2'),
         url('../fonts/ScriptFonts/R-Jenthill/jenthill.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'davishlight';
    src: url('../fonts/ScriptFonts/S-DavishLight/DavishLight.woff2') format('woff2'),
         url('../fonts/ScriptFonts/S-DavishLight/DavishLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'adelynefreeregular';
    src: url('../fonts/ScriptFonts/T-Adelyne/adelyne.woff2') format('woff2'),
         url('../fonts/ScriptFonts/T-Adelyne/adelyne.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silent_landfieldregular';
    src: url('../fonts/ScriptFonts/V-SilentLandfield/silent_landfield.woff2') format('woff2'),
         url('../fonts/ScriptFonts/V-SilentLandfield/silent_landfield.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'revelstokeregular';
    src: url('../fonts/ScriptFonts/W-RevelstokeRegular/RevelstokeRegular.woff2') format('woff2'),
         url('../fonts/ScriptFonts/W-RevelstokeRegular/RevelstokeRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'halimunregular';
    src: url('../fonts/ScriptFonts/Y-Halimun/halimun.woff2') format('woff2'),
         url('../fonts/ScriptFonts/Y-Halimun/halimun.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'heart_breath_demoregular';
    src: url('../fonts/ScriptFonts/Z-HeartBreath/heart_breath_demo.woff2') format('woff2'),
         url('../fonts/ScriptFonts/Z-HeartBreath/heart_breath_demo.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'beautybailey';
    src: url('../fonts/ScriptFonts/G-BeautyBailey/Beauty-Bailey.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'corinthiago';
    src: url('../fonts/ScriptFonts/U-Corinthiago/Corinthiago.eot');
    src: url('../fonts/ScriptFonts/U-Corinthiago/Corinthiago.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ScriptFonts/U-Corinthiago/Corinthiago.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'shadowsintolight';
    src: url('../fonts/ScriptFonts/X-ShadowsIntoLight/ShadowsIntoLight.woff2') format('woff2'),
        url('../fonts/ScriptFonts/X-ShadowsIntoLight/ShadowsIntoLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

