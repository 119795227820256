@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
/* Print Fonts */
@font-face {
    font-family: 'caviar_dreamsbold';
    src: url(/static/media/caviardreams_bold.c95fb14e.woff2) format('woff2'),
         url(/static/media/caviardreams_bold.b2bd6edb.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'janefrediction';
    src: url(/static/media/JaneFrediction.f624c3bc.woff2) format('woff2'),
         url(/static/media/JaneFrediction.a6ab6a25.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'pumpkinpielatte';
    src: url(/static/media/Pumpkin-Pie-Lattes-FREE.6783c956.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gloucester';
    src: url(/static/media/glecb.caa8735c.woff2) format('woff2'),
         url(/static/media/glecb.9d373662.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'steelfish';
    src: url(/static/media/steelfish_bd.633b4300.woff2) format('woff2'),
         url(/static/media/steelfish_bd.68f814af.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'laborunion';
    src: url(/static/media/LaborUnion-Regular.ca2957d7.woff2) format('woff2'),
        url(/static/media/LaborUnion-Regular.133f9ece.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'davishboldinline';
    src: url(/static/media/DavishBoldInline.be33f9f3.woff2) format('woff2'),
         url(/static/media/DavishBoldInline.d6e4591f.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

/* SCript Fonts */
@font-face {
    font-family: 'babyhomeregular';
    src: url(/static/media/babyhome.dcda141f.woff2) format('woff2'),
         url(/static/media/babyhome.b8cd01d0.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'hertine';
    src: url(/static/media/hertine.c0d87867.woff2) format('woff2'),
         url(/static/media/hertine.93d50339.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'josephsophiaregular';
    src: url(/static/media/josephsophia.9d3b636b.woff2) format('woff2'),
         url(/static/media/josephsophia.9db33b2e.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'alexbrushregular';
    src: url(/static/media/alexbrush-regular.705ba101.woff2) format('woff2'),
         url(/static/media/alexbrush-regular.4c18ba1d.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'soulmateregular';
    src: url(/static/media/soulmate.57977bb8.woff2) format('woff2'),
         url(/static/media/soulmate.8beca4a2.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'wedding_by_mandalaregular';
    src: url(/static/media/wedding_by_mandala.0e4d9f73.woff2) format('woff2'),
         url(/static/media/wedding_by_mandala.17e09a86.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'amarilloregular';
    src: url(/static/media/amarillo.2939e9d5.woff2) format('woff2'),
         url(/static/media/amarillo.7c435f90.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'vistadaliva';
    src: url(/static/media/VistaLaDiva.87a44e8c.woff2) format('woff2'),
         url(/static/media/VistaLaDiva.78bfd578.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'amalfi_coastregular';
    src: url(/static/media/amalfi_coast.be5673e4.woff2) format('woff2'),
         url(/static/media/amalfi_coast.5738f73c.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'madina_cleanregular';
    src: url(/static/media/set_sail_studios_-_madina_clean.33fd4410.woff2) format('woff2'),
         url(/static/media/set_sail_studios_-_madina_clean.d87fecad.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'summerstylish';
    src: url(/static/media/SummerStylish.9d52e5b0.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'hello_valenticaregular';
    src: url(/static/media/hello_valentina.45f252bd.woff2) format('woff2'),
         url(/static/media/hello_valentina.d79b370c.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'friday_vibesregular';
    src: url(/static/media/friday_vibes.0b131c0e.woff2) format('woff2'),
         url(/static/media/friday_vibes.629a3819.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'milkshakeregular';
    src: url(/static/media/milkshake.237a0148.woff2) format('woff2'),
         url(/static/media/milkshake.aaa9c87a.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'exposedly_personal_useregular';
    src: url(/static/media/exposedly.dfc9705e.woff2) format('woff2'),
         url(/static/media/exposedly.abb7c058.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'showloveregular';
    src: url(/static/media/showlove-regular.be7eaa4e.woff2) format('woff2'),
         url(/static/media/showlove-regular.46bc1dfc.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'royal_stamford_demoregular';
    src: url(/static/media/royal_stamford.2d4ae79f.woff2) format('woff2'),
         url(/static/media/royal_stamford.d023c654.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ortisan_signatureregular';
    src: url(/static/media/ortisansignature.e03ef5df.woff2) format('woff2'),
         url(/static/media/ortisansignature.d3162043.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'jenthillregular';
    src: url(/static/media/jenthill.1ef1eb76.woff2) format('woff2'),
         url(/static/media/jenthill.1d7f7525.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'davishlight';
    src: url(/static/media/DavishLight.44c350bb.woff2) format('woff2'),
         url(/static/media/DavishLight.8033ce72.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'adelynefreeregular';
    src: url(/static/media/adelyne.a3f3df9c.woff2) format('woff2'),
         url(/static/media/adelyne.bd83bbb3.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silent_landfieldregular';
    src: url(/static/media/silent_landfield.a45a95a0.woff2) format('woff2'),
         url(/static/media/silent_landfield.0bf33f5a.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'revelstokeregular';
    src: url(/static/media/RevelstokeRegular.b90a40e1.woff2) format('woff2'),
         url(/static/media/RevelstokeRegular.4f44a32f.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'halimunregular';
    src: url(/static/media/halimun.0f349359.woff2) format('woff2'),
         url(/static/media/halimun.9724fd22.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'heart_breath_demoregular';
    src: url(/static/media/heart_breath_demo.d23d178b.woff2) format('woff2'),
         url(/static/media/heart_breath_demo.60623b08.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'beautybailey';
    src: url(/static/media/Beauty-Bailey.d49783eb.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'corinthiago';
    src: url(/static/media/Corinthiago.31d6cfe0.eot);
    src: url(/static/media/Corinthiago.31d6cfe0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Corinthiago.6c0dcc8d.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'shadowsintolight';
    src: url(/static/media/ShadowsIntoLight.596cc238.woff2) format('woff2'),
        url(/static/media/ShadowsIntoLight.d1903387.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


* {
    font-family: Poppins;
}

body, main {
    background-color: #f2f6f4;
}

a {
    text-decoration: none !important;
}

a:hover {
    color: #1d1d1d !important;
}

.ds {
    font-family: Dancing Script;
}

.print-a3 {
    font-family: pumpkinpielatte;
}

.print-a4 {
    font-family: caviar_dreamsbold;
}

.print-a5 {
    font-family: janefrediction;
}

.print-a6 {
    font-family: gloucester;
}

.print-a7 {
    font-family: steelfish;
}

.print-a8 {
    font-family: laborunion;
}

.print-a9 {
    font-family: davishboldinline;
}

.print-s {
    font-family: davishlight;
}

.print-w {
    font-family: revelstokeregular;
}

.print-x {
    font-family: shadowsintolight;
}

.script-a {
    font-family: babyhomeregular;
}

.script-a1 {
    font-family: hertine;
}

.script-a2 {
    font-family: josephsophiaregular;
}

.script-b {
    font-family: alexbrushregular;
}

.script-c {
    font-family: soulmateregular;
}

.script-d {
    font-family: wedding_by_mandalaregular;
}

.script-e {
    font-family: amarilloregular;
}

.script-f {
    font-family: vistadaliva;
}

.script-h {
    font-family: amalfi_coastregular;
}

.script-i {
    font-family: madina_cleanregular;
}

.script-j {
    font-family: summerstylish;
}

.script-k {
    font-family: hello_valenticaregular;
}

.script-l {
    font-family: friday_vibesregular;
}

.script-m {
    font-family: milkshakeregular;
}

.script-n {
    font-family: exposedly_personal_useregular;
}

.script-o {
    font-family: showloveregular;
}

.script-p {
    font-family: royal_stamford_demoregular;
}

.script-q {
    font-family: ortisan_signatureregular;
}

.script-r {
    font-family: jenthillregular;
}

.script-s {
    font-family: davishlight;
}

.script-t {
    font-family: adelynefreeregular;
}

.script-v {
    font-family: silent_landfieldregular;
}

.script-y {
    font-family: halimunregular;
}

.script-z {
    font-family: heart_breath_demoregular;
}

.script-g {
    font-family: beautybailey;
}

.script-u {
    font-family: corinthiago;
}

nav {
    padding: 20px;
}

.mp0 {
    margin: 0 !important;
    padding: 0;
}

span {
    font-family: Poppins;
}

.navbar-toggler {
    box-shadow: none !important;
    border: none !important;
}

a.navbar-brand {
    margin-left: 10px;
}

.navbar-brand img{
    height: 4rem;
}

.type.form-control {
    border-radius: 6px !important;
    height: 44px;
}

.typebox {
    padding: 18px;
}

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    padding-left: 0;
}

div.scrollmenu div {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    border-radius: 30px;
    background-color: #9AC1B0;
    width: 45%;
    margin-left: 0;
    margin-top: 1rem;
}

div.scrollmenu div:last-child {
    margin-left: 5%;
}

div.scrollmenu .form-check-input {
    margin-left: 5px;
    margin-right: 10px;
    float: none;
}

.form-check-input:checked {
    background-color: #458808;
    border-color: #208208;
}

.columns-wrapper {
    padding: 20px;
    margin-top: 20px;
}

h3 {
    font-size: 16px !important;
}

h2 {
    font-size: 20px !important;
}

.column {
    max-width: 100%;
    border: 1px solid #f5f5f5;
    padding: 20px;
    box-shadow: 0 0px 10px #b4d2c5;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 50px;
    position: relative;
    cursor: pointer;
    transition: margin 0.5s;
}

.column:hover {
    box-shadow: 0 0px 15px -2px #9bc0af;
    margin-top: -10px;
}

.column p {
    font-size: 44px;
    overflow-wrap: break-word;
}

.column-body {
    padding-top: 35px;
    padding-bottom: 60px;
}

.column-footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 25px;
}

.column-footer span {
    position: relative;
    bottom: -3px;
    font-size: 18px;
    margin-right: 5px;
}

nav .collapse.navbar-collapse {
    position: absolute;
    right: 0;
}

.ml-05 {
    margin-left: 0.5rem;
}

.ml-025 {
    margin-left: 0.25rem;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.w-sm-75 {
    width: 75%!important;
}

.w-sm-100 {
    width: 100%!important;
}

.w-sm-25 {
    width: 25%!important;
}

@media screen and (max-width: 767px) {
    .navbar-collapse.collapse.show {
        height: 100vh;
        z-index: 999;
        top: 0;
        background: #fff;
        padding: 4rem;
        position: fixed;
    }

    .navbar-collapse.collapse.show h1{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

@media screen and (min-width: 767px) {
    .w-md-75 {
        width: 75%!important;
    }
    .w-md-100 {
        width: 100%!important;
    }
    .w-md-25 {
        width: 25%!important;
    }
    div.scrollmenu {
        padding-left: 20px;
    }
    div.scrollmenu div {
        margin-left: 5%;
        margin-top: 0;
    }
    .navbar-brand img{
        height: 6rem;
    }
}
