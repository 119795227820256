@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
* {
    font-family: Poppins;
}

body, main {
    background-color: #f2f6f4;
}

a {
    text-decoration: none !important;
}

a:hover {
    color: #1d1d1d !important;
}

.ds {
    font-family: Dancing Script;
}

.print-a3 {
    font-family: pumpkinpielatte;
}

.print-a4 {
    font-family: caviar_dreamsbold;
}

.print-a5 {
    font-family: janefrediction;
}

.print-a6 {
    font-family: gloucester;
}

.print-a7 {
    font-family: steelfish;
}

.print-a8 {
    font-family: laborunion;
}

.print-a9 {
    font-family: davishboldinline;
}

.print-s {
    font-family: davishlight;
}

.print-w {
    font-family: revelstokeregular;
}

.print-x {
    font-family: shadowsintolight;
}

.script-a {
    font-family: babyhomeregular;
}

.script-a1 {
    font-family: hertine;
}

.script-a2 {
    font-family: josephsophiaregular;
}

.script-b {
    font-family: alexbrushregular;
}

.script-c {
    font-family: soulmateregular;
}

.script-d {
    font-family: wedding_by_mandalaregular;
}

.script-e {
    font-family: amarilloregular;
}

.script-f {
    font-family: vistadaliva;
}

.script-h {
    font-family: amalfi_coastregular;
}

.script-i {
    font-family: madina_cleanregular;
}

.script-j {
    font-family: summerstylish;
}

.script-k {
    font-family: hello_valenticaregular;
}

.script-l {
    font-family: friday_vibesregular;
}

.script-m {
    font-family: milkshakeregular;
}

.script-n {
    font-family: exposedly_personal_useregular;
}

.script-o {
    font-family: showloveregular;
}

.script-p {
    font-family: royal_stamford_demoregular;
}

.script-q {
    font-family: ortisan_signatureregular;
}

.script-r {
    font-family: jenthillregular;
}

.script-s {
    font-family: davishlight;
}

.script-t {
    font-family: adelynefreeregular;
}

.script-v {
    font-family: silent_landfieldregular;
}

.script-y {
    font-family: halimunregular;
}

.script-z {
    font-family: heart_breath_demoregular;
}

.script-g {
    font-family: beautybailey;
}

.script-u {
    font-family: corinthiago;
}

nav {
    padding: 20px;
}

.mp0 {
    margin: 0 !important;
    padding: 0;
}

span {
    font-family: Poppins;
}

.navbar-toggler {
    box-shadow: none !important;
    border: none !important;
}

a.navbar-brand {
    margin-left: 10px;
}

.navbar-brand img{
    height: 4rem;
}

.type.form-control {
    border-radius: 6px !important;
    height: 44px;
}

.typebox {
    padding: 18px;
}

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    padding-left: 0;
}

div.scrollmenu div {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    border-radius: 30px;
    background-color: #9AC1B0;
    width: 45%;
    margin-left: 0;
    margin-top: 1rem;
}

div.scrollmenu div:last-child {
    margin-left: 5%;
}

div.scrollmenu .form-check-input {
    margin-left: 5px;
    margin-right: 10px;
    float: none;
}

.form-check-input:checked {
    background-color: #458808;
    border-color: #208208;
}

.columns-wrapper {
    padding: 20px;
    margin-top: 20px;
}

h3 {
    font-size: 16px !important;
}

h2 {
    font-size: 20px !important;
}

.column {
    max-width: 100%;
    border: 1px solid #f5f5f5;
    padding: 20px;
    box-shadow: 0 0px 10px #b4d2c5;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 50px;
    position: relative;
    cursor: pointer;
    transition: margin 0.5s;
}

.column:hover {
    box-shadow: 0 0px 15px -2px #9bc0af;
    margin-top: -10px;
}

.column p {
    font-size: 44px;
    overflow-wrap: break-word;
}

.column-body {
    padding-top: 35px;
    padding-bottom: 60px;
}

.column-footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 25px;
}

.column-footer span {
    position: relative;
    bottom: -3px;
    font-size: 18px;
    margin-right: 5px;
}

nav .collapse.navbar-collapse {
    position: absolute;
    right: 0;
}

.ml-05 {
    margin-left: 0.5rem;
}

.ml-025 {
    margin-left: 0.25rem;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.w-sm-75 {
    width: 75%!important;
}

.w-sm-100 {
    width: 100%!important;
}

.w-sm-25 {
    width: 25%!important;
}

@media screen and (max-width: 767px) {
    .navbar-collapse.collapse.show {
        height: 100vh;
        z-index: 999;
        top: 0;
        background: #fff;
        padding: 4rem;
        position: fixed;
    }

    .navbar-collapse.collapse.show h1{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

@media screen and (min-width: 767px) {
    .w-md-75 {
        width: 75%!important;
    }
    .w-md-100 {
        width: 100%!important;
    }
    .w-md-25 {
        width: 25%!important;
    }
    div.scrollmenu {
        padding-left: 20px;
    }
    div.scrollmenu div {
        margin-left: 5%;
        margin-top: 0;
    }
    .navbar-brand img{
        height: 6rem;
    }
}